<template>
  <profile-setting></profile-setting>
</template>

<script>
import ProfileSetting from './ProfileSetting.vue';

export default {
  components: {
    ProfileSetting,
  },
};
</script>
