<template>
  <div class="element-wrapper">
    <h6 class="element-header">Team Members</h6>
    <div class="element-box-tp">
      <div class="profile-tile" v-for="user in user_list" :key="user.id">
        <a class="profile-tile-box" href="#">
          <div class="pt-avatar-w">
            <!-- <img alt="" :src="generic_profile"> -->
            <i class="os-icon os-icon-user"></i>
          </div>
          <div class="pt-user-name">
            {{ user.username + " " + user.lastname }}
          </div>
        </a>
        <div class="profile-tile-meta">
          <ul>
            <!-- <li> Last Login:<strong>Online Now</strong> </li> -->
            <!-- <li> Tickets:<strong><a href="#">12</a></strong> </li> -->
            <!-- <li> Response Time:<strong>2 hours</strong> </li> -->
            <li>(No Position Assigned)</li>
          </ul>
          <!-- <div class="pt-btn">
            <a class="btn btn-success btn-sm" href="#">Send Message</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "../../../../service/httpClient";

export default {
  data() {
    return {
      profile1: require("../../../../assets/img/avatar2.jpg"),
      profile2: require("../../../../assets/img/avatar3.jpg"),
      generic_profile: require("../../../../assets/img/company3.png"),
      user_list: [],
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      const org = localStorage.getItem("organization");
      httpClient.getData("account/" + org + "/").then((userlist) => {
        console.log("User List");
        console.log(userlist);
        this.user_list = userlist;
      });
    },
  },
};
</script>
